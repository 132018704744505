import { addresses } from './constants';
import Contract from './Contract';
import { BNtoNum } from './utils';
import ineryTokenABI from '../ABIs/ineryToken.json';

export default class Web3Wrapper {
  web3;
  chainId;
  account;
  wrapperOptions;

  constructor(web3, chainId, account, options = {}) {
    this.web3 = web3;
    this.chainId = chainId;
    this.account = account;

    this.wrapperOptions = {
      web3, chainId, account,
      ...options
    }
    
    this.ineryToken = new Contract(this.wrapperOptions, "IneryToken", ineryTokenABI, addresses.ineryToken[this.chainId])
  }

  async getAccountData() {
    const balance = await this.ineryToken.call("balanceOf", this.account);
    return {
      ineryBalance: BNtoNum(balance, 18),
    }
  }

  async getClaimedTokens(address, abi) {
    const balance = await new Contract(this.wrapperOptions, "Contract", abi, address[this.chainId]).call("releasedAmount", this.account);
    return BNtoNum(balance, 18)
  }

  async getReleasableAmount(address, abi) {
    const res = await new Contract(this.wrapperOptions, "Contract", abi, address[this.chainId]).call("getReleasableAmount", this.account);
    return BNtoNum(res, 18)
  }

  claim(address, abi) {
    const contract = new Contract(this.wrapperOptions, "Contract", abi, address[this.chainId]);
    contract.send("claim")
    .then(res=> console.log(res))
    .catch(err => console.log(err))
  }
}
