import React, { useState } from "react";
import "../css/tutorials.css";
import arrowRight from '../images/vectors/arrow-right-solid.svg';
import Tutorial from "./Tutorial";
import { useSwipeable } from "react-swipeable";

export default function Tutorials({ tutorials }) {
    const [index, setIndex] = useState(0);
    const previousStep = (e) => {
        if (index === 0)
            return;
        setIndex(index - 1);
    };
    const nextStep = (e) => {
        if (index === tutorials.length - 1)
            return;
        setIndex(index + 1);
    };
    return (
        <React.Fragment>
            <div className="tutorials desktop">
                <div className={`arrow rotate-left ${index === 0 ? 'disabled' : ""}`} onClick={previousStep}><img src={arrowRight} alt="arrow-left" /></div>
                <Tutorial data={tutorials[index]} index={index + 1} />
                <div className={`arrow ${index === tutorials.length - 1 ? 'disabled' : ""}`} onClick={nextStep} ><img src={arrowRight} alt="arrow-right" /></div>
            </div>
            <div className="tutorials mobile">
                <p>Swipe for changing steps</p>
                <Tutorial handlers={useSwipeable({ onSwipedLeft: nextStep, onSwipedRight: previousStep })} data={tutorials[index]} index={index + 1} />
            </div>
        </React.Fragment>
    );
}