import React from 'react'

function ClaimInfo({text}) {
    return (
        <div className='claim-info'>
            <div className='border-blue br-20 box-bg mb-20'>
                <div className='label'>
                    <div className='label-circle'></div>
                    <p>Info</p>
                </div>
                <div className='info-text'>
                    <p>
                    {text}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ClaimInfo