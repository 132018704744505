import React from "react";

export default function Tutorial({ data, handlers, index }) {
    const tutorialsDirectory = "tutorial-images/";
    return (
        <React.Fragment>
            <div className="border-blue br-20 box-bg tutorial-container" {...handlers}>
                <div className='label'>
                    <div className='label-circle'></div>
                    <p>{`Step ${index}`}</p>
                </div>
                <div className="tutorial">
                    <div style={{ display: data.image === null ? "none" : "block" }}>
                        <img src={data.image === null ? "" : tutorialsDirectory + data.image} alt="tutorial img" />
                    </div>
                    <div className="description" dangerouslySetInnerHTML={{ __html: data.description }}>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}