class Contract {
  web3;
  chainId;
  account;
  tag;
  events;
  contract;
  address;

  constructor(options, tag, abi, address) {
    this.web3 = options.web3;
    this.chainId = options.chainId;
    this.account = options.account;

    this.contract = new this.web3.eth.Contract(abi, address);

    if (tag) this.tag = tag;
    else this.tag = "contract-" + Date.now();

    this.events = {};
    this.address=address;
  }
  
  call(method, ...params) {
    return new Promise((resolve, reject) => {
      this.contract.methods[method](...params).call({from: this.account})
        .then(resolve)
        .catch(reject)
    });
  }

  send(method, options, ...params) {
    return new Promise((resolve, reject) => {
      this.contract.methods[method](...params).send({...options, from: this.account})
        .then(resolve)
        .catch(reject)
    });
  }

  on(event, callback, onerr) {
    if (this.events[event])
      return;
    this.contract.events[event]((err, res) => {
      if (err === null) {
        callback(res.returnValues, this.tag);
      } else {
        if (onerr) onerr(err);
        else console.log(err);
      }
    });
    this.events[event] = true;
  }

  // getPastEvents(...params) {
  //   return new Promise((resolve, reject) => {
  //     this.contract.getPastEvents(...params)
  //       .then(resolve)
  //       .catch(reject)
  //   });
  // }
}

export default Contract;