import React, { useState, useRef, useEffect, useContext } from 'react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import '../css/landingPage.css';
import arrowDown from '../images/vectors/Vector 64.png';
import ineryLogo from '../images/logos/inery-logo.png';
import logoWhite from '../images/logos/rect6009.png'
import copy from '../images/icons/iconmonstr-clipboard-2-240.png';
import check from '../images/vectors/clipboard-check-solid.svg';
import useAccountData from '../hooks/useAccountData';
import { Web3ModalContext } from '../contexts/Web3ModalProvider';

import huobiLogo from "../images/logos/huobi.png";

export default function LandingPage() {
    const arrowRef = useRef(null);
    const popupMsg = useRef(null);
    const contractKey = useRef(null);
    const [open, setOpen] = useState(false);
    const [walletBallance, setWalletBalance] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const accountData = useAccountData();
    const { account } = useContext(Web3ModalContext);
    const setWidth = () => {
        setScreenWidth(window.innerWidth)
    }
    useEffect(() => {
        let div = document.getElementById("script-after");
        const script1 = document.createElement("script");
        script1.setAttribute("src", "https://s3.tradingview.com/tv.js");
        div.after(script1);

        const script2 = document.createElement("script");
        script2.innerHTML = `new TradingView.widget(
            {
            "autosize": true,
            "symbol": "HUOBI:INRUSDT",
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "dark",
            "style": "1",
            "locale": "en",
            "toolbar_bg": "#f1f3f6",
            "enable_publishing": false,
            "range": "1M",
            "withdateranges": true,
            "hide_side_toolbar": false,
            "container_id": "tradingview_44569"
          }
            );`;
        script1.after(script2);
    }, []);
    useEffect(() => {
        window.addEventListener('resize', setWidth)
        return () => {
            window.removeEventListener('resize', setWidth)
        };
    }, [screenWidth]);

    useEffect(() => {
        if (accountData) {
            setWalletBalance(accountData.ineryBalance);
        }
    }, [accountData]);

    useEffect(() => {
        if (account) {
            return
        } else {
            setOpen(false)
        }
    }, [account]);

    return (
        <React.Fragment>
            <div className='landing-page'>
                <div className='landing-page-left'>
                    <div className='wallet-info-box border-blue br-20 box-bg'>
                        <div className='label'>
                            <div className='label-circle'></div>
                            <p>Wallet</p>
                        </div>
                        <div className='wallet-info p-30'>
                            <div className='currencies'>
                                <div>
                                    <p className='name-text'>INR</p>
                                    <p className='prices-text'>{account ? walletBallance.toLocaleString('en-US') : 'Not available'}</p>
                                </div>
                                {account ? <div className='arrow-down' onClick={() => {
                                    setOpen(!open)
                                    if (open) {
                                        arrowRef.current.style.transform = 'rotate(0deg)'
                                    } else {
                                        arrowRef.current.style.transform = 'rotate(180deg)'
                                    }
                                }}>
                                    <img ref={arrowRef} src={arrowDown} alt="arrow-down" />
                                </div> : null}
                            </div>
                            <SlideDown>
                                {open ? (
                                    <div className='total-value-container'>
                                        <div className='total-value'>
                                            <p className='name-text'>Total Value</p>
                                            <h2 className='prices-text'>{`$${(walletBallance * 0.4).toLocaleString('en-US')}`}</h2>
                                        </div>
                                        <img src={logoWhite} alt="logo" />
                                    </div>
                                ) : null}
                            </SlideDown>
                        </div>
                    </div>
                    <div className="border-blue br-20 box-bg trading-container">
                        <div className='label'>
                            <div className='label-circle'></div>
                            <p>Trading on</p>
                        </div>
                        <div className="trading">
                            <a href="https://www.huobi.com/en-us/exchange/inr_usdt/" className="trading-platform" target="_blank" rel="noreferrer">
                                <div className="trading-logo">
                                    <img src={huobiLogo} alt="Huobi logo" />
                                </div>
                                <div className="trading-name">
                                    <h2>Huobi</h2>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='landing-page-right'>
                    <div className='token-info-box border-blue br-20 box-bg'>
                        <div className='label'>
                            <div className='label-circle'></div>
                            <p>Token info</p>
                        </div>
                        <div className='token-info'>
                            <div className='token-symbol'>
                                <p className='name-text'>Symbol</p>
                                <div>
                                    <img src={ineryLogo} alt="logo" />
                                    <h4>INR</h4>
                                </div>
                            </div>
                            <div className='smart-contract'>
                                <p className='name-text'>Smart Contract</p>
                                <div className='contract-key' ref={contractKey}>
                                    <p>0xaB725d0A10C3f24725c89F5765Ae5794a26C1336</p>
                                </div>
                                <div className='copy-to-clipboard'
                                    onClick={() => {
                                        navigator.clipboard.writeText(contractKey.current.textContent)
                                        popupMsg.current.classList.add('active');
                                        setTimeout(() => {
                                            popupMsg.current.classList.remove('active');
                                        }, 1500);
                                    }}>
                                    <img src={copy} alt='copy-icon' />
                                    <div ref={popupMsg} className='popup-msg'>
                                        <p>Copied</p>
                                        <img src={check} alt="check" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='market-data-box border-blue br-20 box-bg'>
                        <div className='label-and-currency-labels'>
                            <div className='label'>
                                <div className='label-circle'></div>
                                <p>Market Data</p>
                            </div>
                            <div className='currency-labels'>
                                <p>INR/USD</p>
                            </div>
                        </div>
                        <div className="market-data-info" style={{ height: "500px", paddingTop: "20px", paddingBottom: "20px" }}>
                            <div className="tradingview-widget-container" style={{ witdh: "100%", height: "100%" }}>
                                <div id="tradingview_44569" style={{ witdh: "100%", height: "90%" }}></div>
                                <div className="tradingview-widget-copyright" id="script-after"><a href="https://www.tradingview.com/symbols/INRUSDT/?exchange=HUOBI" rel="noreferrer" target="_blank"><span className="blue-text">INRUSDT Chart</span></a> by TradingView</div>
                            </div>

                            {/*<p style={{color: 'white', marginTop: 20}}>Display values for the price chart will be available after the INERY token goes live on market listing.</p>
                         <div className='market-data-values'>
                            <div className='value-item mb-35'>
                                <p className='name-text'>Current INR price</p>
                                <h2 className='prices-text'>$0.280077 <span>+4.5%</span></h2>
                            </div>
                            <div className='value-item mb-35'>
                                <p className='name-text'>Volume 24h</p>
                                <h2 className='prices-text'>$7,542,311</h2>
                            </div>
                            <div className='value-item'>
                                <p className='name-text'>24h High/Low</p>
                                <h2 className='prices-text'>0.0.29015 / 0.280077</h2>
                            </div>
                        </div>
                        <div className='market-data-chart'>
                            <div className='chart'>
                                <ResponsiveContainer
                                width='99%'
                                height='100%'
                                padding={0}
                                >
                                    <LineChart
                                        data={data}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 20,
                                        }}
                                        >
                                        <CartesianGrid vertical={false} stroke='#33B6E1' opacity={0.5}/>
                                        {screenWidth <= 860 ? null :<YAxis  orientation='right' axisLine={false} dx={15}/>}
                                        <Line  dataKey="uv" stroke="#33B6E1" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className='chart-time'>
                                <p>Recent</p>
                                <p>24h</p>
                                <p>1 mon</p>
                                <p>3 mon</p>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    {/* <div className='total-value-locked-box border-blue br-20 box-bg'>
                    <div className='label'>
                        <div className='label-circle'></div>
                        <p>Total Value Locked</p>
                    </div>
                    <div className='total-value-locked-info'>
                        <div className='locked-values'>
                            <div className='value-item'>
                                <p className='name-text'>TVL</p>
                                <h2 className='prices-text'>$1,234,678.12</h2>
                            </div>
                        </div>
                        <div className='total-value-locked-chart'>
                            <div className='chart'>
                                <ResponsiveContainer
                                    width='99%'
                                    height='100%'
                                >
                                    <LineChart
                                        data={data}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 20,
                                        }}
                                        >
                                        <CartesianGrid vertical={false} stroke='#33B6E1' opacity={0.5}/>
                                        {screenWidth <= 860 ? null :<YAxis  orientation='right' axisLine={false} dx={15}/>}
                                        <Line  dataKey="uv" stroke="#33B6E1" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className='chart-time'>
                                <p>Recent</p>
                                <p>24h</p>
                                <p>1 mon</p>
                                <p>3 mon</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
        </React.Fragment>
    )
}
