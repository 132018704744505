export const defaultChainId = 56;

export const rpcUrls = {
  1: 'https://mainnet.infura.io/v3/' + process.env.REACT_APP_INFURA_PROJECT_ID,
  4: 'https://rinkeby.infura.io/v3/' + process.env.REACT_APP_INFURA_PROJECT_ID,
  56: 'https://bsc-dataseed1.binance.org',
  97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
}

export const networkNames = {
  1: 'Ethereum Mainnet',
  4: 'Rinkeby Test Network',
  56: 'BSC Main Network',
  97: 'BSC Test Network',
};

export const addresses={
  ineryToken : {
    56: "0xaB725d0A10C3f24725c89F5765Ae5794a26C1336",
  },
  seedVesting:{
    56: "0x06e8dface02cb1b2e00962f9ce3a9c7e19c5bafb"
  },
  privateAVesting:{
    56: "0x3341fcfbafca4e736b86480553b9dbe1a8018b33"
  },
  strategicVesting:{
    56: "0xe1f3b21d13c9766ab174f7851094a94d1bee6051"
  },
  preSaleVesting:{
    56: "0xeab56d1b79c06b6e5bcf66191cac2391ff1ff164"
  },
  saleVesting:{
    56: "0xba1c50f4f229f00e0bfed2ae9c8a091864400546"
  }
};
