import React from "react";
import { Routes, Route } from "react-router-dom";
import './css/App.css';
import Header from './components/Header';
import LandingPage from './components/LandingPage'
import Claim from './components/Claim';
import MobileNav from './components/MobileNav';

function App() {

  return (
    <div className="App">
        <Header/>
        <MobileNav/>
        <main className='container'>
          <Routes>
            <Route path='/' element={<LandingPage />}/>
            <Route path='/claim' element={<Claim/>}/>
          </Routes>
        </main>
    </div>
  );
}

export default App;
