import React, {useRef, useEffect} from 'react';
import '../css/walletIdPopUp.css';
import close from '../images/vectors/times-solid.svg';
export default function WalletIdPopUp({id, active, closePopUp}) {
    const popUpRef = useRef(null);
    
    const handleClosePopUp = (e)=> {
        if(popUpRef.current && !popUpRef.current.contains(e.target)){
            closePopUp()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClosePopUp, true)
        return () => {
            document.removeEventListener('click', handleClosePopUp, true)
        };
    });
    return (
        <div ref={popUpRef} className={`id-popup ${active ? 'id-popup-active': null} br-20`}>
            <div>
                <p>Wallet ID:</p>
                <img onClick={()=>closePopUp()} src={close} alt="close" />
            </div>
            <h4>{id}</h4>
        </div>
    )
}
